import React from 'react'
import { Link, graphql } from 'gatsby'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'
import appendLinkToParagraph from '../utils/preview-text'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    
    return (
      <DefaultLayout>
        <SEO
          title={siteTitle}
          keywords={[`blog`, `fabisevich`, `swift`, `ios`, `design`, `indie`, `startup`, `writing`]}
        />
        <div className="content-box clearfix">
          {posts.map(({ node }) => {
            var previewText = appendLinkToParagraph(node.excerpt, node.fields.slug)

            return (
              <article className="post" key={node.fields.slug}>
                <div className="post-content">
                  <h2 className="post-title">
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </h2>

                  <div className="post-metadata">
                    {node.frontmatter.date} | {node.timeToRead} minute read
                  </div>

                  <div dangerouslySetInnerHTML={{ __html: `${previewText}` }} />
                </div>
              </article>
            )
          })}

          <div className="container">
            <div className="pagination" role="navigation">
              {!isFirst && (
                  <Link to={`/${prevPage}`} rel="prev" className="newer-posts">
                    ← Previous
                  </Link>
              )}

              <span className="page-number">
                Page {currentPage} of {numPages}
              </span>

              {!isLast && (
                  <Link to={`/${nextPage}`} rel="next" className="older-posts">
                    Next →
                  </Link>
              )}
            </div>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { published: {ne: false} } }
    ) {
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMM D, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
